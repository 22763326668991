
//===============LOADER OVERLAY/ hero animation============================================================================//

const loaderOverlay = document.querySelector('.loader-overlay');
const loaderVideo = document.querySelector('.loader-video');
const heroAnimationElements = document.querySelectorAll('.hero-animation-element-is-disabled');

loaderVideo.addEventListener('ended', function() {
  loaderOverlay.classList.add('loader-disabled');
  heroAnimationElements.forEach(function(element) {
    element.classList.add('hero-animation-element-is-active');
  });
});

//===============ANIMATED TITLE============================================================================//

document.addEventListener('DOMContentLoaded', function () {
  // Animation for the h1 element
  const titleElement = document.getElementById('animated-title');
  const titleText = titleElement.textContent;
  const words = titleText.split(' ');

  titleElement.innerHTML = words.map((word, index) => {
    return `<span><div class="word" style="--index: ${index};">${word}</div></span>`;
  }).join(' ');

  // Animation for the h2 element
  const subtitleElement = document.getElementById('animated-subtitle');
  const subtitleText = subtitleElement.textContent;
  const subtitleWords = subtitleText.split(' ');

  subtitleElement.innerHTML = subtitleWords.map((word, index) => {
    return `<span><div class="word" style="--index: ${index};">${word}</div></span>`;
  }).join(' ');
});



// Code JavaScript pour faire suivre le rond de la souris
const mouseFollower = document.querySelector('.mouse-follower');
const header = document.querySelector('header');

document.addEventListener('mousemove', (e) => {
  const mouseX = e.clientX + window.scrollX;
  const mouseY = e.clientY + window.scrollY;

  mouseFollower.style.transform = `translate(${mouseX - 25}px, ${mouseY - 25}px)`;
});

header.addEventListener('mouseleave', () => {
  mouseFollower.classList.add('mouse-follower-is-disabled');
});

header.addEventListener('mouseenter', () => {
  mouseFollower.classList.remove('mouse-follower-is-disabled');
});


// automatisation de l'activation des liens de telechargement===================================

//===============SAFARI============================================================================//
//no need of these because it was only broken on Anthony's computer

// // Vérifier si le navigateur est Safari
// const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

// if (isSafari) {
//   // Suppression du loader-overlay
//   const loaderOverlay = document.querySelector('.loader-overlay');
//   loaderOverlay.style.display = 'none';

//   // Suppression des animations de logo dans le hero
//   heroAnimationElements.forEach(function (element) {
//     element.classList.add('hero-animation-element-is-active');
//   });
//   //animated-title, ajouter une classe supplémentaire pour supprimer le delay sur safari
//   document.addEventListener('DOMContentLoaded', function () {
//     const titleElement = document.getElementById('animated-title');
//     const titleText = titleElement.textContent;
//     const words = titleText.split(' ');
  
//     titleElement.innerHTML = words.map((word, index) => {
//         return `<span><div class="word-safari" style="--index: ${index};">${word}</div></span>`;
//     }).join(' ');
//   });
//   //retirer le mouse-follower qui se dédouble sur safari
//   const mouseFollower = document.querySelector('.mouse-follower');
//   mouseFollower.style.display = 'none';
// } 

